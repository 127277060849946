<template>
  <div>
    <div class="van-main">
      <div class="van-common-detail">
        <van-cell-group>
          <van-cell title="我的订单" is-link to="/order/myOrder" />
          <van-cell title="我的合同" is-link to="/contract/myContract" />
          <van-cell title="我的提单" is-link to="/billofloading/list" />
          <van-cell title="我要提货" is-link to="/delivery/menu" />
          <van-cell title="我的付款" is-link to="/payment/list"/>
          <van-cell title="我的发票" is-link to="/invoice/invoiceList" />
        </van-cell-group>
      </div>
    </div>
    <tab-bar active="service" />
  </div>
</template>

<script>
import TabBar from '@/components/tab-bar'
import { Cell, CellGroup } from 'vant'
export default {
  components: {
    TabBar,
    [Cell.name]: Cell,
    [CellGroup.name]: CellGroup
  },
  data () {
    return {
    }
  },
  created () {
  },
  methods: {
  }
}
</script>

<style scoped>

</style>
