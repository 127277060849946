var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("div", { staticClass: "van-main" }, [
        _c(
          "div",
          { staticClass: "van-common-detail" },
          [
            _c(
              "van-cell-group",
              [
                _c("van-cell", {
                  attrs: {
                    title: "我的订单",
                    "is-link": "",
                    to: "/order/myOrder"
                  }
                }),
                _c("van-cell", {
                  attrs: {
                    title: "我的合同",
                    "is-link": "",
                    to: "/contract/myContract"
                  }
                }),
                _c("van-cell", {
                  attrs: {
                    title: "我的提单",
                    "is-link": "",
                    to: "/billofloading/list"
                  }
                }),
                _c("van-cell", {
                  attrs: {
                    title: "我要提货",
                    "is-link": "",
                    to: "/delivery/menu"
                  }
                }),
                _c("van-cell", {
                  attrs: {
                    title: "我的付款",
                    "is-link": "",
                    to: "/payment/list"
                  }
                }),
                _c("van-cell", {
                  attrs: {
                    title: "我的发票",
                    "is-link": "",
                    to: "/invoice/invoiceList"
                  }
                })
              ],
              1
            )
          ],
          1
        )
      ]),
      _c("tab-bar", { attrs: { active: "service" } })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }